<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="店铺名称">
                <el-input size="small" v-model="store"></el-input>
            </el-form-item>
            <el-form-item label="店铺电话">
                <el-input size="small" v-model="mobile"></el-input>
            </el-form-item>
            <el-form-item label="关联订单单号">
                <el-input size="small" v-model="goods_order_sn"></el-input>
            </el-form-item>
            <el-form-item label="用户名称">
                <el-input size="small" v-model="nickname"></el-input>
            </el-form-item>
            <el-form-item label="产生时间">
                <el-date-picker v-model="form.create_time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结算状态">
                <el-select v-model="form.type" size="small" placeholder="请选择" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="item in type" :key="item.value"></el-option>
                </el-select>
            </el-form-item>
            <!--<el-form-item label="订单类型">
                <el-select v-model="form.service_type" size="small" placeholder="请选择">
                    <el-option label="商品卖出" value="1"></el-option>
                    <el-option label="提现" value="9"></el-option>
                </el-select>
            </el-form-item>-->
            <el-form-item label="收入类型">
                <el-select v-model="form.status" size="small" placeholder="请选择">
                    <el-option label="入账" value="1"></el-option>
                    <el-option label="出账" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                <!--<el-button type="primary" @click="searchType" size="small">导出</el-button>-->
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!--<div class="descriptions">
            <div class="descriptions_item">筛选后订单金额：￥{{ orderAmount }}</div>
            <div class="descriptions_item">筛选后订单数量：{{ orderNum }}</div>
            <div class="descriptions_item">筛选后平台抽佣金额：￥{{ platformCommission }}</div>
            <div class="descriptions_item">筛选后商家结算金额：￥{{ settlementAmount }}</div>
        </div>-->
        <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="商品归属" align="center">
                <template slot-scope="scope">{{ scope.row.store_id == 0 ? '平台商品' : '店铺商品' }}</template>
            </el-table-column>
            <el-table-column label="店铺名称" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.store_id == 0 ? '--' : scope.row.store_info.store_name
                }}</template>
            </el-table-column>
            <el-table-column label="商家姓名" align="center">
                <template slot-scope="scope">{{ scope.row.store_id == 0 ? '--' : scope.row.store_info.merchant_name
                }}</template>
            </el-table-column>
            <el-table-column label="商家电话" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.store_id ? scope.row.store_info.merchant_mobile : '--'
                }}</template>
            </el-table-column>
            <el-table-column label="注册账号" align="center" width="140">
                <template slot-scope="scope">{{
                    scope.row.user_info ? scope.row.user_info.mobile : scope.row.order_info ? scope.row.order_info.mobile :
                    '--' }}</template>
            </el-table-column>
            <el-table-column label="平台佣金" align="center">
                <template slot-scope="scope">{{ scope.row.rate * 100 }}%</template>
            </el-table-column>
            <el-table-column label="结算比例" align="center">
                <template slot-scope="scope">{{ scope.row.percentage * 100 }}%</template>
            </el-table-column>
            <el-table-column label="商品总金额" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.goods_amount : '--'
                }}</template>
            </el-table-column>
            <el-table-column label="订单支付金额" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.amount : '--' }}</template>
            </el-table-column>
            <el-table-column label="余额支付金额" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.pd_amount : '--' }}</template>
            </el-table-column>
            <el-table-column label="是否折扣券" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.reduction_amount > 0 ? '是' : '否'
                    :
                    '否'
                }}</template>
            </el-table-column>
            <el-table-column label="折扣金额" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.reduction_amount : '--'
                }}</template>
            </el-table-column>
            <el-table-column label="商家结算金额" align="center" width="140" prop="money"></el-table-column>
            <el-table-column label="订单状态" align="center">
                <template slot-scope="scope">{{ status.find(v => v.value == scope.row.order_info.state) ? status.find(v =>
                    v.value == scope.row.order_info.state).label : '--' }}</template>
            </el-table-column>
            <el-table-column label="结算状态" align="center">
                <template slot-scope="scope">{{ type.find(v => v.value == scope.row.type).label }}</template>
            </el-table-column>
            <el-table-column label="关联单号" align="center" prop="order_sn" width="140"> </el-table-column>
            <el-table-column label="下单用户账号" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.order_info ? scope.row.order_info.user_name : '--' }}</template>
            </el-table-column>
            <el-table-column label="下单用户身份" align="center" width="140">
                <template slot-scope="scope">{{ scope.row.is_vip ? '会员' : '普通用户' }}</template>
            </el-table-column>
            <el-table-column label="产生时间" align="center" width="160">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                keyworks_type: '',
                keyworks: '',
                create_time: '',
                type: '',
                //service_type: '',
                status: ''
            },
            store: '',
            mobile: '',
            goods_order_sn: '',
            nickname: '',
            type: [
                {
                    value: 1,
                    label: '待入账'
                },
                {
                    value: 2,
                    label: '已入账'
                },
                {
                    value: 3,
                    label: '已失效'
                },
                {
                    value: 4,
                    label: '冻结中'
                }
            ],
            status: [
                {
                    value: 0,
                    label: '已取消'
                },
                {
                    value: 10,
                    label: '未付款'
                },
                {
                    value: 20,
                    label: '待发货'
                },
                {
                    value: 30,
                    label: '待收货'
                },
                {
                    value: 40,
                    label: '已完成'
                }
            ],
            list: [],
            total: 0,
            orderAmount: 0,
            orderNum: 0,
            platformCommission: 0,
            settlementAmount: 0
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList (page = 0) {
            let data = Object.assign({}, this.form)
            if (page) {
                data.page = 1
            }
            if (this.store) {
                data.keyworks_type = 'store'
                data.keyworks = this.store
            }
            if (this.mobile) {
                data.keyworks_type = 'mobile'
                data.keyworks = this.mobile
            }
            if (this.goods_order_sn) {
                data.keyworks_type = 'goods_order_sn'
                data.keyworks = this.goods_order_sn
            }
            if (this.nickname) {
                data.keyworks_type = 'nickname'
                data.keyworks = this.nickname
            }
            if (data.create_time) {
                data.create_time = [data.create_time[0] / 1000, data.create_time[1] / 1000,]
            } else {
                delete data.create_time
            }
            this.$axios
                .post(this.$api.finance.commodityIncome, data)
                .then(res => {
                    if (res.code == 0) {
                        let { total, list, orderAmount, orderNum, platformCommission, settlementAmount } = res.result
                        this.total = total
                        this.list = list
                        this.orderAmount = orderAmount
                        this.orderNum = orderNum
                        this.platformCommission = platformCommission
                        this.settlementAmount = settlementAmount
                    }
                });
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                keyworks_type: '',
                keyworks: '',
                create_time: '',
                type: '',
                //service_type: '',
                status: ''
            }
            this.getList()
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
